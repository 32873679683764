<template>
  <div class="menu">
    <v-col lg="2" class="side-menu scroll  tw-mt-2">

<!--      <div class="tw-flex tw-flex-row ml-7 my-2">-->
<!--        <v-icon :color="companyColor" size="24" class="pr-2">-->
<!--          mdi-magnify-->
<!--        </v-icon>-->
<!--        <v-col-->
<!--          class="tw-flex tw-items-center tw-flex-row"-->
<!--          style="-->
<!--            border-right: 1px solid rgba(79, 79, 79, 0.06);-->
<!--            border-left: 1px solid rgba(79, 79, 79, 0.06);-->
<!--            height: 60px;-->
<!--          "-->
<!--        >-->
<!--          <v-menu offset-y>-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <v-text-field-->
<!--                placeholder="Search "-->
<!--                hide-details-->
<!--                solo-->
<!--                flat-->
<!--                v-bind="attrs"-->
<!--                v-on="on"-->
<!--              />-->
<!--            </template>-->
<!--            <v-list>-->
<!--              <v-list-item v-for="(item, index) in allThings" :key="index">-->
<!--                <v-list-item-title style="color: black">{{-->
<!--                  item-->
<!--                }}</v-list-item-title>-->
<!--              </v-list-item>-->
<!--            </v-list>-->
<!--          </v-menu>-->
<!--        </v-col>-->
<!--        <v-icon :color="companyColor" size="24" class="px-5">-->
<!--          mdi-bell-outline-->
<!--        </v-icon>-->
<!--      </div>-->
      <div
        :class="selectedTab === tab ? 'selected' : 'select-div'"
        class="my-3 mx-5 tw-pt-40 tw-cursor-pointer"
        @click="handleSelectedTab(tab)"
        v-for="(tab, index) in tabs"
        :key="index"
      >
        <v-icon v-if="tab === selectedTab" :color="companyColor"
          >mdi-power-on</v-icon
        >
        <icons
          :name="tab"
          :color="selectedTab === tab ? companyColor : '#4F4F4F'"
        />
        <label
          class="pl-4 text-capitalize"
          :style="{ color: selectedTab === tab ? companyColor : '#4F4F4F' }"
          >{{ tab }}</label
        >
      </div>
      <div
        class="
          tw-flex tw-items-center tw-flex-row
         tw-mt-10 tw-cursor-pointer
         ml-5
         tw-mb-20
        "
        @click="handleLogout"
      >
        <icons :color="companyColor" name="logout" />
        <span class="pl-4 text-capitalize" :style="{ color: companyColor }"
          >logout</span
        >
      </div>
    </v-col>
  </div>
</template>

<script>

import Icons from "../reuseables/Icons";
import { mapGetters } from "vuex";
import { logout } from "@/services/api/AuthApiService";

export default {
  name: "TerminalManagerMobileSideMenu",
  components: { Icons },

  props: {
    companyColor: {
      type: [String],
      default: "#004AAD",
    },
    companyEmail: [String],
    companyName: [String],
    companyLogo: [String],
  },
  data() {
    return {
      selectedTab: "dashboard",
      items: ["Logout"],
      allThings: [],
      tabs: [
        "dashboard",
        "booking",
        "manifest",
        "terminals",
        "fleet",
        "managers",
        "booking admins",
        "pilots",
        "routes",
        "trips",
        "wallet",
      ],
    };
  },
  computed: {
    ...mapGetters("dashboardComponent", ["currentComponent"]),
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    handleSelectedTab(tab) {
      this.selectedTab = tab;
      if (this.selectedTab === "dashboard") {
        if (this.$route.name !== "CompanyStaffDashboard")
          this.$router.push({ name: "CompanyStaffDashboard" });
      } else if (this.selectedTab === "terminals") {
        if (this.$route.name !== "TerminalManagerTerminals")
          this.$router.push({ name: "TerminalManagerTerminals" });
      } else if (this.selectedTab === "managers") {
        if (this.$route.name !== "TerminalManagerManagers")
          this.$router.push({ name: "TerminalManagerManagers" });
      } else if (this.selectedTab === "booking admins") {
        if (this.$route.name !== "TerminalManagerBookingAdmins")
          this.$router.push({ name: "TerminalManagerBookingAdmins" });
      } else if (this.selectedTab === "pilots") {
        if (this.$route.name !== "TerminalManagerPilots")
          this.$router.push({ name: "TerminalManagerPilots" });
      } else if (this.selectedTab === "fleet") {
        if (this.$route.name !== "TerminalManagerFleets")
          this.$router.push({ name: "TerminalManagerFleets" });
      } else if (this.selectedTab === "routes") {
        if (this.$route.name !== "TerminalManagerRoutes")
          this.$router.push({ name: "TerminalManagerRoutes" });
      } else if (this.selectedTab === "trips") {
        if (this.$route.name !== "TerminalManagerTrips")
          this.$router.push({ name: "TerminalManagerTrips" });
      } else if (this.selectedTab === "booking") {
        if (this.$route.name !== "TerminalManagerBookings")
          this.$router.push({ name: "TerminalManagerBookings" });
      }
      else if(this.selectedTab === "wallet"){
        if(this.$route.name !== "TerminalWallet"){
          this.$router.push({ name: "TerminalWallet" });
        }
      }
      else if( this.selectedTab === "manifest"){
        if (this.$route.name !== 'AllManifestCompanyStaff'){
          this.$router.push({ name: "AllManifestCompanyStaff" });
        }
      }else if (this.selectedTab === "settings") {
        if (this.$route.name !== "Settings")
          this.$router.push({ name: "Settings" });
      }
      sessionStorage.setItem("terminalDashboardComponent", tab);
      this.handleSetCurrentComponent();
    },
    handleSetCurrentComponent() {
      let component = sessionStorage.getItem("terminalDashboardComponent");
      this.$store.dispatch("terminalDashboardComponent/setCurrentComponent", component);
    },
    handleLogout() {
      logout();
    },

  },
  created() {
    let userData = JSON.parse(sessionStorage.getItem("userData"))
    if (userData ){
      if (userData.realm_access.roles.includes("TRANSPORT_COMPANY_MANAGER")){
        userData.userRole= "transportCompanyManager"
      }
      else {
        this.tabs = this.tabs.filter(tab => tab !== "wallet")
      }
    }
    let component = sessionStorage.getItem("terminalDashboardComponent");
    if (!component) {
      sessionStorage.setItem("terminalDashboardComponent", "dashboard");
      this.handleSetCurrentComponent();
    } else {
      this.selectedTab = component;
      this.$store.dispatch("terminalDashboardComponent/setCurrentComponent", component);

      this.handleSelectedTab(this.selectedTab);
    }
  },
};
</script>

<style scoped lang="scss">
.side-menu {
  display: flex;
  background: #ffffff;
  width: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0 !important;
  flex-direction: column;
}

.select-div {
  background: white;
  height: 50px;
  width: auto;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 20px;
}
.select-div:hover {
  background: #f5f7f9;
  height: 50px;
}

.selected {
  height: 50px;
  background: #f5f7f9;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.company-name {
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.company-email {
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 10px;
  color: #bdbdbd;
  @media screen and (min-width: 1900px) {
    font-size: 12px;
  }
}
.menu-drop-down {
  width: 60%;
  @media screen and (max-width: 11450px) {
    width: 80%;
  }
}
.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
  height: 90vh;
}
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 8px double transparent;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: transparent;
}
::-webkit-scrollbar-track {
  background: #FFFFFF;
  border: 10px solid transparent;
  border-radius: 6px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
</style>
