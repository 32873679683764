<template>
  <v-col sm="2" class="side-menu tw-mt-10" v-if="$vuetify.breakpoint.mdAndUp">
    <div
      :class="selectedTab === tab ? 'selected' : 'select-div'"
      class="my-3 ml-9 tw-cursor-pointer"
      @click="handleSelectedTab(tab)"
      v-for="(tab, index) in tabs"
      :key="index"
    >
      <v-icon v-if="tab === selectedTab" :color="companyColor"
        >mdi-power-on</v-icon
      >
      <icons
        :name="tab"
        :color="selectedTab === tab ? companyColor : '#4F4F4F'"
      />
      <label
        class="pl-4 text-capitalize"
        :style="{ color: selectedTab === tab ? companyColor : '#4F4F4F' }"
        >{{ tab }}</label
      >
    </div>
    <div @click="handleLogout"
      class="tw-flex  tw-items-center tw-flex-row ml-9 tw-mt-10 tw-cursor-pointer"
    >
      <icons :color="companyColor" name="logout" />
      <span class="pl-4 text-capitalize" :style="{ color: companyColor }"
        >logout</span
      >
    </div>
  </v-col>
</template>

<script>
import Icons from "../reuseables/Icons";
import { mapGetters } from "vuex";
import {logout} from "@/services/api/AuthApiService";
export default {
  name: "SideMenu",
  components: { Icons },
  props: {
    companyColor: {
      type: [String],
      default: "#004AAD",
    },
  },
  data() {
    return {
      selectedTab: "dashboard",
      tabs: [
        "dashboard",
        "booking",
        "manifest",
        "terminals",
        "fleet",
        "managers",
        "booking admins",
        "pilots",
        "routes",
        "trips",
        "wallet",
      ],
    };
  },
  computed: {
    ...mapGetters("terminalDashboardComponent", ["currentComponent"]),
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    handleSelectedTab(tab) {
      this.selectedTab = tab;
      if (this.selectedTab === "dashboard") {
        if (this.$route.name !== "CompanyStaffDashboard")
        this.$router.push({ name: "CompanyStaffDashboard" });
      } else if (this.selectedTab === "terminals") {
        if (this.$route.name !== "TerminalManagerTerminals")
        this.$router.push({ name: "TerminalManagerTerminals" });
      } else if (this.selectedTab === "managers") {
        if (this.$route.name !== "TerminalManagerManagers")
        this.$router.push({ name: "TerminalManagerManagers" });
      } else if (this.selectedTab === "booking admins") {
        if (this.$route.name !== "TerminalManagerBookingAdmins")
        this.$router.push({ name: "TerminalManagerBookingAdmins" });
      } else if (this.selectedTab === "pilots") {
        if (this.$route.name !== "TerminalManagerPilots")
        this.$router.push({ name: "TerminalManagerPilots" });
      } else if (this.selectedTab === "fleet") {
        if (this.$route.name !== "TerminalManagerFleets")
        this.$router.push({ name: "TerminalManagerFleets" });
      } else if (this.selectedTab === "routes") {
        if (this.$route.name !== "TerminalManagerRoutes")
        this.$router.push({ name: "TerminalManagerRoutes" });
      } else if (this.selectedTab === "trips") {
        if (this.$route.name !== "TerminalManagerTrips")
        this.$router.push({ name: "TerminalManagerTrips" });
      } else if (this.selectedTab === "booking") {
        if (this.$route.name !== "TerminalManagerBookings")
        this.$router.push({ name: "TerminalManagerBookings" });
      }
      else if(this.selectedTab === "wallet"){
        if(this.$route.name !== "TerminalWallet"){
          this.$router.push({ name: "TerminalWallet" });
        }
      }
      else if( this.selectedTab === "manifest"){
        if (this.$route.name !== 'AllManifestCompanyStaff'){
          this.$router.push({ name: "AllManifestCompanyStaff" });
        }
      }
      sessionStorage.setItem("terminalDashboardComponent", tab);
      this.handleSetCurrentComponent();
    },
    handleSetCurrentComponent() {
      let component = sessionStorage.getItem("terminalDashboardComponent");
      this.$store.dispatch(
        "terminalDashboardComponent/setCurrentComponent",
        component
      );
    },
    handleLogout(){
      logout()
    },
  },
  created() {
    let userData = JSON.parse(sessionStorage.getItem("userData"))
    if (userData ){
      if (userData.realm_access.roles.includes("TRANSPORT_COMPANY_MANAGER")){
        userData.userRole= "transportCompanyManager"
      }
      else {
       this.tabs = this.tabs.filter(tab => tab !== "wallet")
      }
    }
    let component = sessionStorage.getItem("terminalDashboardComponent");
    if (!component) {
      sessionStorage.setItem("terminalDashboardComponent", "dashboard");
      this.handleSetCurrentComponent();
    } else {
      this.selectedTab = component;
      this.$store.dispatch(
        "terminalDashboardComponent/setCurrentComponent",
        component
      );
      this.handleSelectedTab(this.selectedTab);
    }
  },
};
</script>

<style scoped lang="scss">
.side-menu {
  display: flex;
  background: #ffffff;
  width: 100%;
  height: available;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 1.5rem 0 0 0 !important;
  flex-direction: column;
}

.select-div {
  height: 50px;
  background: white;
  width: auto;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
  flex-direction: row;
}
.select-div:hover {
  height: 50px;
  background: #f5f7f9;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
}

.selected {
  height: 50px;
  background: #f5f7f9;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
}
</style>
